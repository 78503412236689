import React from 'react';
import { Box, Typography, TextField, IconButton } from '@mui/material';
import MUIButton from './MUIButton';
import { Facebook, Twitter, Instagram, YouTube } from '@mui/icons-material';

const MUIFooter = () => {
  return (
    <Box sx={{ backgroundColor: '#000', color: '#fff', padding: '2rem 0', textAlign: 'center' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxWidth: 1180, margin: '0 auto', flexDirection: { xs: 'column', sm: 'row' } }}>
        
        {/* Left Section */}
        <Box sx={{ flex: 1, textAlign: { xs: 'center', sm: 'left' }, marginBottom: { xs: '1rem', sm: 0 } }}>
          <Typography variant="h6">Barrie Concert & Georgian Music Association</Typography>
          <Typography>info@barrieconcerts.org</Typography>
          <Typography>(705)-436-1232</Typography>
          <Typography>(705)-726-1181</Typography>
          <Typography>P.O. Box 452 Barrie, ON L4M 4T7</Typography>
        </Box>

        {/* Center Section */}
        <Box sx={{ flex: 1, textAlign: 'center' }}>
          <Box component="img" src="/images/BCA-logo3.jpg" alt="Logo" sx={{ width: 70, height: 'auto', marginBottom: '1rem' }} />
          <Typography>Follow Us</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '10px' }}>
            <IconButton href='https://www.facebook.com' target='_blank' aria-label='Facebook' sx={{ color: '#fff' }}>
              <Facebook />
            </IconButton>
            <IconButton href='https://www.twitter.com' target='_blank' aria-label='Twitter' sx={{ color: '#fff' }}>
              <Twitter />
            </IconButton>
            <IconButton href='https://www.instagram.com' target='_blank' aria-label='Instagram' sx={{ color: '#fff' }}>
              <Instagram />
            </IconButton>
            <IconButton href='https://www.youtube.com' target='_blank' aria-label='YouTube' sx={{ color: '#fff' }}>
              <YouTube />
            </IconButton>
          </Box>
          <Typography sx={{ fontSize: '10px', color: '#F4E8C7', marginTop: '20px' }}>
            Copyright © 2024 Barrie Concerts & Georgian Music<br />
            Designed by Evelyn Fu
          </Typography>
        </Box>

        {/* Right Section */}
        <Box sx={{ flex: 1, textAlign: { xs: 'center', sm: 'center' }, marginTop: { xs: '1rem', sm: 0 } }}>
          <Typography variant="h6">Sign Up</Typography>
          <Typography sx={{ color: '#d2a01d', padding: '5% 0' }}>Get access to exclusive updates</Typography>
          <Box component="form" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TextField type='email' placeholder='Your Email' variant='outlined' size='small' sx={{ marginRight: 2, backgroundColor: '#fff', borderRadius: '4px' }} />
            <a href="http://eepurl.com/iTJOUM" target="_blank" style={{ textDecoration: 'none' }}>
              <MUIButton variant='primary' type='button'>
                Submit
              </MUIButton>
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default MUIFooter;
