import React from 'react';
import { Card, CardMedia, CardContent, Typography, Link } from '@mui/material';

const ReadMoreCard = ({ title, description, imageUrl, link }) => (
  <Link href={link} style={{ textDecoration: 'none' }}>
    <Card>
      <CardMedia
        component="img"
        height="200"
        style={{ objectFit: 'cover', height: '230px' }}
        image={imageUrl}
        alt={title}
      />
      <CardContent>
        <Typography variant="h6" component="div" >
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
        <Typography
          variant="body2"
          color="#0e0e0e"
          sx={{
            textDecoration:'underline',
            '&:hover':{
                color: '#b3891a',
                cursor: 'pointer',
            },
          }}
        >
          Read More
        </Typography>
      </CardContent>
    </Card>
  </Link>
);

export default ReadMoreCard;
