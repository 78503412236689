// SingleEvent.js
import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";

function SingleEvent8() {

    const [heroSectionEventData] = useState({
        image: "images/Barrie-Concerts/2024-2025/bc_18_01_25.jpg",
        title: 'True North Brass',
        subtitle: 'Dancing Across the Global',
        date: 'Saturday 18 January 2025 7.30pm',
        location: 'Hiway Church, 50 Anne Street North, Barrie',
        buttonText: 'Book Tickets',
        buttonLink: 'https://www.showpass.com/barrie-concerts-sat-series-2024-2025-bca/ '
    });

    const [eventData] = useState({
        program: `For over twenty years the True North Brass has been a prominent fixture on the landscape of brass chamber music. In 2018 the True North Brass reemerges onto the Canadian and international concert stage with new membership, a new sound and new repertoire, reestablishing itself as one of the world’s finest brass ensembles.
The new True North Brass, representing some of Canada’s most prominent orchestral brass players, is carrying on the True North Brass tradition, equally at home in recital, in music education or collaborating with symphony orchestras.`,
        performers: `Karen Donnelly, Trumpet

Karen Donnelly, from Regina, Saskatchewan, began playing trumpet in elementary school. She holds a Bachelor of Arts in Music from the University of Regina and a Master’s of Music from McGill University. After three seasons as Acting Principal Trumpet of the National Arts Centre Orchestra, she became Principal Trumpet in 1999. Karen has performed with various ensembles, including Orchestra London, CBC, Ottawa Chamber Music Festival, and Capital BrassWorks.

Stéphane Beaulac, Trumpet

Stéphane Beaulac, a former member of the Los Angeles Philharmonic, returned as Principal Trumpet of the Orchestre Métropolitain. He has performed with the Seoul Philharmonic, Toronto Symphony, and National Arts Center Orchestra. Beaulac is active in the chamber music scene and teaches at the University of Montreal and the Conservatoire de Musique de Montréal.

Julie Fauteux, Horn

Julie Fauteux, from Sherbrooke, Quebec, joined the NAC Orchestra in 1999 as associate principal horn. She studied at the Montreal Conservatory, achieving a “Premier Prix.” At 21, she became principal horn of the Orquesta Sinfonica de Galicia in Spain. Julie has soloed with various orchestras and performed with the Berlin Philharmonic Orchestra. She teaches horn at the Conservatoire de Musique de Gatineau.

David Pell, Trombone

David Pell is the Bass Trombonist of the National Ballet of Canada Orchestra and Principal Trombonist of the Hamilton Philharmonic Orchestra. He teaches at the University of Toronto and the Royal Conservatory of Music. David has performed with many orchestras and in various musical theater productions across North America.

Sasha Johnson, Tuba

Sasha Johnson studied at the University of Toronto, Manhattan School of Music, and the Orchestra Academy of the Berlin Philharmonic. He has performed with European orchestras and major music festivals worldwide. Returning to Canada in 2001, Sasha performs with the Orchestre Symphonique de Montréal, National Arts Centre Orchestra, and Toronto Symphony. He teaches at the Royal Conservatory’s Glenn Gould School and McGill University.`,
        performerLink: 'https://truenorthbrass.com/',
        ticketInfo: `Purchase your season subscription early. 6 amazing upcoming concerts. Adults Live-streaming: Available at the time of your choosing for up to 30 days after the concert - $110 Adults In-person: -  $190. Students $35 either in-person or live-streamed`,

    });

    const barrieConcert = [
        {
            title: 'Duo Turgeon',
            description: 'From Bruce with Love',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_05-10-24.jpg',
            link: '/SingleEvent6'
        },
        {
            title: 'Dévah Quartet',
            description: 'Dévah Quartet',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_30-11-24.jpg',
            link: '/SingleEvent7'
        },
        {
            title: 'Ensemble Caprice',
            description: 'Vivaldi on Fire',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_22_02_25.jpg',
            link: '/SingleEvent9'
        },
    ];
    

    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink}
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Barrie Concerts" events={barrieConcert} buttonLink="/BarrieConcert" />
            </Box>
        </Box>
    );
}

export default SingleEvent8;
