// MUICard5.js
import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import MUIButton from './MUIButton'; 

const MUICard5 = ({ event }) => {
  return (
    <Card sx={{ width: 350, height: 400, margin: '20px' }}>
      <CardMedia
        component="img"
        height="200"
        width="345"
        image={event.image}
        alt={event.title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {event.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {event.date}
        </Typography>
        <Typography variant="body2" color="text.secondary" paragraph>
          {event.description}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
          <a href={event.bookTicketLink} target='_blank'>
          <MUIButton variant="primary" size="small" sx={{ backgroundColor: '#d2a01d', color: 'black', padding: '10px 20px', fontWeight: 'bold' ,'&:hover': { backgroundColor: 'white'} }}>Book Tickets</MUIButton>
          </a>
          <a href={event.learnMoreLink} target='_blank'>
          <MUIButton variant="primary" size="small" sx={{ backgroundColor: 'white', color: 'black', padding: '10px 20px'}} >Learn More</MUIButton>
          </a>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MUICard5;
