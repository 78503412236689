import React from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Link } from '@mui/material';

const PictureTextCard = ({ image, name, description, link }) => {
  return (
    <Link href={link} target="_blank" underline="none" sx={{ textDecoration: 'none' }}>
      <Card 
        sx={{
          display: 'flex', 
          alignItems: 'center', 
          flexDirection: { xs: 'column', md: 'row' },  
          textDecoration: 'none',
          boxShadow: 3
        }}
      >
       
        <Box sx={{ width: { xs: '100%', md: '50%' }, textAlign: 'center' }}>
          <CardMedia
            component="img"
            sx={{
              height: { xs: 'auto', md: '200px' },
              width: { xs: '100%', md: '350px' },  
              objectFit: 'contain'
            }}
            image={image}
            alt={name}
          />
        </Box>
        
        <Box sx={{ width: { xs: '100%', md: '50%' }, padding: '20px' }}>
          <CardContent>
            <Typography component="div" variant="h5" gutterBottom>
              {name}
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
              {description}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </Link>
  );
};

export default PictureTextCard;
