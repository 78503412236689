import { Box, Typography } from '@mui/material';
import React from 'react';

const HeroSectionEventCard = ({ backgroundImage, heading, description }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '70vh',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1,
        }}
      />

      <Typography variant="h1" component="h1" sx={{ zIndex: 2 }}>
        {heading}
      </Typography>
      <Typography variant="body1" sx={{ zIndex: 2, mt: 2, maxWidth: '600px' }}>
        {description}
      </Typography>
    </Box>
  );
};

export default HeroSectionEventCard;
