// SingleEvent.js
import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";

function News2() {

    const [heroSectionEventData] = useState({
        image: "images/News/gm_04-02-24_lgabout.jpg",
        title: "Experience the Unique Sound of Ladom Ensemble at Bethel Community Church",
        subtitle: "Ladom Ensemble",
        buttonText: 'More Events',
        buttonLink: '/Events'
    });

    const [eventData] = useState({
        program: `Formed in 2007 while studying at the University of Toronto, the members of Ladom Ensemble bring diverse backgrounds and influences to their music. The ensemble includes Michael Bridge (accordion) from Alberta, Beth Silver (cello) from Toronto, Adam Campbell (percussion) from Prince Edward Island, and Pouya Hamidi (piano) from Ontario by way of Tehran, Iran. Pouya, the in-house composer, creates original compositions that fuse Persian and classical music, while the group’s repertoire also draws from Argentinean tango, Serbian folk, and classical music, among others.

Ladom Ensemble’s performances are a reflection of an authentically Canadian experience, combining multiple musical traditions with a Western classical toolset. They have commissioned works from several Canadian composers and have been praised for their innovative and captivating performances.`,

performers: `Michael Bridge (accordion): Known for his exceptional talent, Michael is pursuing his doctorate in accordion at the University of Toronto and has been named one of CBC’s “30 Hot Classical Musicians Under 30.”
Beth Silver (cello): Beth’s playful and spontaneous style adds a unique dynamic to the group.
Adam Campbell (percussion): Adam’s energetic and versatile percussion skills bring an infectious enthusiasm to the ensemble.
Pouya Hamidi (piano): Pouya’s deep love for music and humble nature make him a standout performer and composer.`,
ticketInfo: `This was our previous event. If you want to purchase a 2024/25 season subscription, please click the button in the top right corner.`
    });

    const georgianMusic = [
        {
            title: 'Andromeda Trio',
            description: 'Tchaikovsky, Shostakovich, Haydn',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_15-09-24.jpg',
            link: '/SingleEvent'
        },
        {
            title: 'Ishay Shaer',
            description: 'Bach, Beethoven, Debussy, Schumann',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_03-11-24.jpg',
            link: '/SingleEvent2'
        },
        {
            title: 'Venuti Quartet',
            description: 'Indigo',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_15-12-24.jpg',
            link: '/SingleEvent3'
        }
    ];
    
    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}  
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink}
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Georgian Music" events={georgianMusic} buttonLink="/GeorgianMusic" />
            </Box>
        </Box>
    );
}

export default News2;
