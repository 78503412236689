import React from 'react';
import { Box, Typography, Card, CardMedia, CardContent, Link } from '@mui/material';

const ReadMoreCard = ({ title, description, imageUrl, link }) => (
  <Link href={link} style={{ textDecoration: 'none' }}>
    <Card>
      <CardMedia
        component="img"
        height="300"
        width="auto"
        style={{ objectFit: 'cover', height: '300px' }}
        image={imageUrl}
        alt={title}
      />
      <CardContent>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
        <Typography
          variant="body2"
          color="#0e0e0e"
          sx={{
            textDecoration: 'underline',
            '&:hover': {
              color: '#b3891a',
              cursor: 'pointer',
            },
          }}
        >
          Read More
        </Typography>
      </CardContent>
    </Card>
  </Link>
);

const Highlights = ({ highlights }) => (
  <Box sx={{ padding: '20px', marginX: '25px', marginTop: '25px' }}>
    <Typography variant="h4" component="div" sx={{ color: '#fff', textAlign: 'center', marginBottom: '20px' }}>
      Highlights
    </Typography>
    <Box
  sx={{
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    justifyContent: 'center',
  }}
>
  {highlights.map((highlight, index) => (
    <Box key={index} sx={{ width: { xs: '100%', sm: 'calc(50% - 10px)', md: 'calc(33.33% - 15px)' } }}>
      <ReadMoreCard {...highlight} />
    </Box>
  ))}
</Box>

  </Box>
);

export default Highlights;
