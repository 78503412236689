import React from 'react';
import CustomCarousel from './CustomCarousel';
import { Box, Typography, Container } from '@mui/material';
import MUIButton from './MUIButton';

const MUIHeroSection = ({ slides }) => {
  return (
    <CustomCarousel>
      {slides.map((slide, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            backgroundImage: `url(${slide.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            color: 'white',
            textAlign: 'center',
            padding: 3
          }}
        >
          <Container maxWidth="md">
            <Typography variant="h2" component="h1" gutterBottom>
              {slide.title}
            </Typography>
            <Typography variant="h5" component="h2" paragraph>
              {slide.description}
            </Typography>
            <a href={slide.heroSectionLink}>
            <MUIButton variant="secondary" size="large">
              {slide.buttonText}
            </MUIButton>
            </a>
          </Container>
        </Box>
      ))}
    </CustomCarousel>
  );
};

export default MUIHeroSection;
