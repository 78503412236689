import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemButton, ListItemText, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

// Updated pages array with 'All Events', 'Georgian Music', and 'Barrie Concerts'
const pages = [
  { name: "Home", path: "/" },
  { name: "All Events", path: "/Events" },  // All Events added
  { name: "Georgian Music", path: "/GeorgianMusic" },  // Georgian Music added
  { name: "Barrie Concerts", path: "/BarrieConcerts" },  // Barrie Concerts added
  { name: "About", path: "/About" },
  { name: "News", path: "/News" },
  { name: "Support Us", path: "/SupportUs" },
  { name: "Order Now", path: "https://www.showpass.com/barrie-concerts-and-georgian-music-2024-2025/" }
];

const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <React.Fragment>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: '#242424',
            color: 'white'
          }
        }}
      >
        <List>
          {pages.map((page, index) => (
            <ListItem key={index} onClick={() => setOpenDrawer(false)}>
              <ListItemButton
                component={Link}
                to={page.path}
              >
                <ListItemText primary={page.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <IconButton
        sx={{ color: "white", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="white" />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
