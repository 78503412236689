import React from 'react'
import { Button } from '@mui/material';

const MUIButton = ({variant, size,sx, ...props}) => {
let styles = {};

switch (variant) {
    case 'primary':
        styles = {
            backgroundColor: '#000000',
            border: '1px solid #d2a01d',
            color: '#d2a01d',
            borderRadius: '5px',
            '&:hover': {
              color: '#000000',
              backgroundColor: '#d2a01d',
            },
            ...sx,
          };
    break;

    case 'secondary':
      styles = {
        backgroundColor: '#d2a01d',
        border: '1px solid #d2a01d',
        borderRadius: '5px',
        color: '#ffffff',
        fontSize: '20px',
        '&:hover': {
          backgroundColor: '#b28717',
        },
        ...sx,
      };
      break;

      case 'tertiary':
        styles = {
          backgroundColor: '#F5E1A4', 
          border: 'none',
          color: '#000000', 
          borderRadius: '5px', 
          padding: '10px 20px', 
          fontWeight: 'bold', 
          '&:hover': {
          backgroundColor: '#ECD893', 
        },
        ...sx, 
      };
      break;

      case 'extraLarge':
        styles = {
          backgroundColor: '#000000',
          border: '1px solid #d2a01d',
          color: '#d2a01d',
          borderRadius: '5px',
          padding: '10px 50px', 
          fontSize: '18px', 
          '&:hover': {
            color: '#000000',
            backgroundColor: '#d2a01d',
          },
          ...sx,
        };
        break;


     default:
      styles = {
        backgroundColor: '#ccc',
        border: '1px solid #999',
        color: '#333',
        '&:hover': {
          backgroundColor: '#bbb',
        }
      };
}

  return <Button sx={styles} size={size} {...props}/>;
};

export default MUIButton;
