import React from 'react';
import { Link } from 'react-router-dom';
import MUIButton from './MUIButton';
import { Box } from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const MUICard = ({ data }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };

  return (
    <Box sx={{ backgroundColor: '#3D3D3D', paddingX: '20px'}}>
      <Slider {...settings}>
        {data.map((card, index) => (
          <Box
            key={index}
            sx={{
              padding: '0 10px', // Padding inside each card to create the gap
              backgroundColor: '#3D3D3D', // Ensure the background color is set for the gap
              boxSizing: 'border-box', // Ensure padding is included in the element's total width and height
              height: '700px', // Fixed height
              width: 'auto',  // Fixed width
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{
                borderRadius: '10px',
                backgroundColor: '#0e0e0e',
                '.cards__item__link': {
                  display: 'flex',
                  flexFlow: 'column',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  textDecoration: 'none',
                },
                '.cards__item__pic-wrap': {
                  position: 'relative',
                  width: '100%',
                  paddingTop: '67%',
                  overflow: 'hidden',
                  '&::after': {
                    content: 'attr(data-category)',
                    position: 'absolute',
                    bottom: '0',
                    marginLeft: '10px',
                    padding: '6px 8px',
                    maxWidth: 'calc(100% - 60px)',
                    fontSize: '12px',
                    fontWeight: '700',
                    color: '#fff',
                    backgroundColor: '#D2A01D',
                    boxSizing: 'border-box',
                  },
                },
                '.cards__item__img': {
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  bottom: '0',
                  left: '0',
                  display: 'block',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  transition: 'all 0.2s linear',
                  '&:hover': {
                    transform: 'scale(1.1)',
                  },
                },
                '.cards__item__info': {
                  padding: '20px 30px 30px',
                  '.cards__item__date, .cards__item__text, .cards__item__location, .cards__item__description': {
                    color: '#fff',
                    fontSize: '18px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    textOverflow: 'ellipsis',
                    
                  },
                  '.cards__item__date': {
                    fontSize: '16px',
                    padding: '2px 0',
                  },
                  '.cards__item__text': {
                    fontSize: '26px',
                    padding: '10px 0',
                  },
                  '.cards__item__description': {
                    color: '#D3D3D3',
                    fontSize: '14px',
                    padding: '5px 0',
                  },
                },
                '.cards__item__btns': {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingBottom: '30px',
                },
              }}
            >
              <Link className='cards__item__link' to={card.path}>
                <Box component="figure" className='cards__item__pic-wrap' data-category={card.label}>
                  <Box component="img" className='cards__item__img' alt={card.label} src={card.src} />
                </Box>
                <Box className='cards__item__info'>
                  <Box component="p" className='cards__item__date'>{card.text_date}</Box>
                  <Box component="h5" className='cards__item__text'>{card.text}</Box>
                  <Box component="p" className='cards__item__description'>{card.text_description}</Box>
                  <Box component="p" className='cards__item__location'>{card.text_location}</Box>
                </Box>
                <Box className='cards__item__btns'>
                  <MUIButton variant='extraLarge'>
                    Learn More
                  </MUIButton>
                </Box>
              </Link>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
}

export default MUICard;
