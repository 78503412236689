// SingleEvent.js
import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";

function News1() {

    const [heroSectionEventData] = useState({
        image: "images/News/bc_30-09-23_lgabout.jpg",
        title: "Reviving the Roaring Twenties with Masterful Musicianship and Jazz Improvisation",
        subtitle: "Spirit '20'",
        buttonText: 'More Events',
        buttonLink: '/Events'
    });

    const [eventData] = useState({
        program: `On September 30, 2023, Hiway Church hosted an unforgettable performance by Spirit '20, a sextet known for their masterful musicianship and captivating renditions of music from the roaring twenties. The concert, part of the Barrie Concerts series, began at 7:30pm and featured a diverse program that thrilled the audience.

Concert Program

Spirit '20 delighted attendees with pieces by Gershwin, Duke Ellington, and Ravel, among others. The ensemble's program drew heavily on the jazz and classical fusion styles of the 1920s, bringing a nostalgic yet vibrant energy to the evening. Highlights included Gershwin's "Rhapsody in Blue," Ellington's soulful tunes, and contemporary pieces inspired by the era, such as Allan Gilliland's "Spirit '20" and David Baker's "Heritage."

Audience Reception

The audience's reception was overwhelmingly positive, with many praising the ensemble's technical prowess and the engaging way they brought history to life through music. The humorous banter between musicians added a layer of charm, making the evening both educational and entertaining.
`,

performers: `Spirit '20 is an ensemble renowned for their mastery in both classical and jazz music. Launched at the Festival of the Sound in 2010, the group comprises six outstanding musicians:

James Campbell (clarinet): A leading classical clarinet soloist from Indiana University, Campbell is also a gifted improviser in dixie, swing, and bebop styles.
Guy Few (trumpet, vocals, piano): A versatile musician, Few is known for his dynamic performances on the trumpet, engaging jazz vocals, and impressive piano skills, including four-hand stunts.
Chloe Dominguez (cello): With extensive contemporary music experience, Dominguez brings depth and precision to the ensemble.
Christian Sharp (bassoon): A virtuoso bassoonist, Sharp’s technique and skill elevate the group's performances.
John Novacek (piano): Novacek’s intensity and dynamism shine through his toe-tapping ragtime pieces, captivating audiences with every performance.
Mark Fewer (violin): A McGill University professor, Fewer excels in various improvisational styles, including baroque, folk, and jazz.
The ensemble’s engaging performances are enriched with humorous banter, creating a lively and authentic experience. Spirit '20 brings the roaring twenties to life, offering audiences a unique blend of historical and cultural music that is both educational and entertaining.`,
        ticketInfo: `This was our previous event. If you want to purchase a 2024/25 season subscription, please click the button in the top right corner.`
    });

    const barrieConcert = [
        {
            title: 'Andromeda Trio',
            description: 'Tchaikovsky, Shostakovich, Haydn',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_15-09-24.jpg',
            link: '/SingleEvent'
        },
        {
            title: 'Ishay Shaer',
            description: 'Bach, Beethoven, Debussy, Schumann',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_03-11-24.jpg',
            link: '/SingleEvent2'
        },
        {
            title: 'Venuti Quartet',
            description: 'Indigo',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_15-12-24.jpg',
            link: '/SingleEvent3'
        }
    ];
    

    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}  
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink}
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Barrie Concerts" events={barrieConcert} buttonLink="/BarrieConcerts" />
            </Box>
        </Box>
    );
}

export default News1;
