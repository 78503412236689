import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import ReadMoreCard from './ReadMoreCard';
import MUIButton from './MUIButton';

const MUICard2 = ({ title, events, buttonLink }) => {
  return (
    <Container>
      <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={2}>
        <Typography variant="h4" component="h2">
          {title}
        </Typography>
        <MUIButton variant="tertiary" onClick={() => window.location.href = buttonLink}>Learn More</MUIButton>
      </Box>
      <Grid container spacing={3}>
        {events.map((event, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ReadMoreCard
              title={event.title}
              description={event.description}
              imageUrl={event.imageUrl}
              link={event.link}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default MUICard2;
