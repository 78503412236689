// SingleEvent.js
import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";

function SingleEvent9() {

    const [heroSectionEventData] = useState({
        image: "images/Barrie-Concerts/2024-2025/bc_22_02_25.jpg",
        title: 'Ensemble Caprice',
        subtitle: 'Vivaldi on Fire',
        date: 'Saturday 22 February 2025 7.30pm',
        location: 'Hiway Church, 50 Anne Street North, Barrie',
        buttonText: 'Book Tickets',
        buttonLink: 'https://www.showpass.com/barrie-concerts-sat-series-2024-2025-bca/ '
    });

    const [eventData] = useState({
        program: `Anonymus (Uhrovska zbierka , 1730) Präambulum 
Antonio Vivaldi (1678-1741) Concerto en do majeur P.81 
 pour 2 flûtes à bec, 2 violons et bc 
 Adagio/Allegro-Largo-Allegro 
 
Anonymus (Uhrovska zbierka , 1730) C 256 / Hungaricus 25 / Hungaricus 23 / 
 Hungaricus 53 / C 106 
 
Antonio Vivaldi Motet In furore iustissimae irae 
 
 
*** 
 
Anonymus (Uhrovska zbierka , 1730) Pulcher/Anglicus/Ballet Doctoris Fausti ad mensam 
Antonio Vivaldi Cantata All ombra di sospetta 
 
Antonio Vivaldi Sonate en sol mineur RV 63 La Follia 
 pour 2 flûtes à bec et bc 
 
Antonio Vivaldi Concerto en sol majeur RV 443 
 pour flautino, cordes et bc 
 Allegro-(Adagio)-Allegro molto (Soliste : Matthias Maute) 
 
Anonymus (Uhrovska zbierka , 1730) Hungaricus 15 / Iag Bari`,
performers: `Janelle Lucyk, soprano 
Matthias Maute-recorder, traverso 
Sophie Larivière-recorder, traverso 
Lucie Ringuette -violon baroque 
Karin Cuellar-violon baroque 
Pemi Paull-violon alto 
Jean-Christophe Lizotte-violoncelle 
David Jacques-guitare baroque 
Ziya Tabassian-percussion `,
        performerLink: 'https://www.ensemblecaprice.com/',
        ticketInfo: `Purchase your season subscription early. 6 amazing upcoming concerts. Adults Live-streaming: Available at the time of your choosing for up to 30 days after the concert - $110 Adults In-person: -  $190. Students $35 either in-person or live-streamed`,

    });

    const barrieConcert = [
        {
            title: 'Duo Turgeon',
            description: 'From Bruce with Love',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_05-10-24.jpg',
            link: '/SingleEvent6'
        },
        {
            title: 'Dévah Quartet',
            description: 'Dévah Quartet',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_30-11-24.jpg',
            link: '/SingleEvent7'
        },
        {
            title: 'True North Brass',
            description: 'Dancing Across the Global',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_18_01_25.jpg',
            link: '/SingleEvent8'
        },
    ];
    

    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}  
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink}
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Barrie Concerts" events={barrieConcert} buttonLink="/BarrieConcert" />
            </Box>
        </Box>
    );
}

export default SingleEvent9;
