// SingleEvent.js
import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";

function News3() {

    const [heroSectionEventData] = useState({
        image: "images/News/Announcement.jpg",
        title: "Exciting News! Our New Website is Live!",
        subtitle: "",
        buttonText: 'More Events',
        buttonLink: '/Events'
    });

    const [eventData] = useState({
        program: `The Barrie Concert Association is thrilled to announce the launch of our brand new website! We've revamped our online presence to provide you with a smoother, more engaging experience. You can now easily explore upcoming concerts, purchase tickets, and stay up-to-date with the latest news and events.

Visit us today and check it out! We're excited to share this new chapter with all of our wonderful supporters and concert lovers.`,

performers: `We are excited to announce that we have invited some of Canada’s top musical ensembles to perform at our upcoming concerts. These incredibly talented groups will bring their passion and artistry to the stage, offering a range of performances that will leave you mesmerized. Whether you are a fan of classical, contemporary, or something in between, there’s something for everyone to enjoy. This is a rare opportunity to experience world-class music right here in our community. We can’t wait to share this magical experience with you, as we celebrate the best of Canadian talent in an intimate and inspiring setting.`,
ticketInfo: `If you want to purchase a 2024/25 season subscription, please click the button in the top right corner.`
    });

    const georgianMusic = [
        {
            title: 'Andromeda Trio',
            description: 'Tchaikovsky, Shostakovich, Haydn',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_15-09-24.jpg',
            link: '/SingleEvent'
        },
        {
            title: 'Ishay Shaer',
            description: 'Bach, Beethoven, Debussy, Schumann',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_03-11-24.jpg',
            link: '/SingleEvent2'
        },
        {
            title: 'Venuti Quartet',
            description: 'Indigo',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_15-12-24.jpg',
            link: '/SingleEvent3'
        }
    ];
    
    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}  
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink}
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Georgian Music" events={georgianMusic} buttonLink="/GeorgianMusic" />
            </Box>
        </Box>
    );
}

export default News3;
