import React from "react";
import '../../App.css';
import HeroSectionEventCard from '../HeroSectionEventCard';
import { Box, Grid, Typography} from '@mui/material';
import FounderCard from '../FounderCard';
import PictureTextCard from '../PictureTextCard';

function About() {
  const eventData = {
    backgroundImage: '/images/bg-1.jpg',
    heading: 'About Barrie Concert Association',
    description: 'Bringing World-Class Music to Barrie'
  };

  const founderData = {
    image: '../../images/BurceOwen.png', 
    name: 'Bruce Owen',
    description: 'Bruce Owen (1931 – 2022) was the founder of the Barrie Concert Association and its guiding light for about 40 years.    His passion for classical and related music and his connections with a large number of musicians enabled him and the Association to present world caliber performances right here in Barrie, year after year.  Bruce had a particular interest in involving young people in classical music, both as performers and audience members. The venues should be listed under a subheading, Venues, and do not need to be anywhere near as prominent as you have them.'
  };

  const churchData = [
    {
      image: '../../images/Logo/Highway-Church.jpg',
      name: 'Highway Church',
      description: '50 Anne St N, Barrie, ON L4N 2B6',
      link:'https://www.hiway.church/'
    },
    {
      image: '../../images/Logo/Bethel-Community-Church.jpg', 
      name: 'Bethel Community Church',
      description: '128 Vincent St., Barrie, ON L4M 3Y9',
      link:'https://bethelbarrie.ca/'
    }
  ];

  return (
    <Box sx={{backgroundColor: '#F1F1F1'}}>
      <HeroSectionEventCard
        backgroundImage={eventData.backgroundImage}
        heading={eventData.heading}
        description={eventData.description}
      />
      <Box sx={{padding: '2% 5%'}}>
        <Typography>The Barrie Concert Association (BCA) is a non-profit organization dedicated to bringing exceptional musical performances to the Barrie community. Since its founding in 1946, the BCA has enriched the local cultural landscape by organizing a series of concerts each season, showcasing a diverse range of genres, from classical to jazz and contemporary music. Under the leadership of Bruce Owen, the BCA has grown into the largest concert presenter in the region. As a charitable organization, the BCA is committed to making world-class music accessible to all while also providing educational opportunities for local youth, fostering a deeper appreciation for the arts at affordable prices.
        </Typography>
      </Box>
      
      <Box sx={{ padding: '0 3%' }}> 
        <FounderCard
          image={founderData.image}
          name={founderData.name}
          description={founderData.description}
        />
      </Box>

  
      <Grid container spacing={3} sx={{  padding: '2% 5%' }}>
        <Grid item xs={12} md={6}>
          <PictureTextCard 
            image={churchData[0].image}
            name={churchData[0].name}
            description={churchData[0].description}
            link={churchData[0].link}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PictureTextCard 
            image={churchData[1].image}
            name={churchData[1].name}
            description={churchData[1].description}
            link={churchData[1].link}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default About;
