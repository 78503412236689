import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import MUIButton from './MUIButton';

const MUISingleEventCard = ({ image, title, subtitle, date, location, buttonText, buttonLink, sx }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={{ padding: 0, margin: 0, width: '100%' }}>
    <Card
  sx={{
    ...sx,
    width: '100%',
    minHeight: { xs: '50vh', md: '100vh' }, // Set minimum height for mobile
    position: 'relative',
    backgroundImage: `url(${image})`,
    backgroundSize: { xs: 'contain', md: 'cover' }, // Adjust image size for mobile and desktop
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: 0,
    padding: 0,
    paddingTop: { xs: '16px', md: '64px' }, 
  }}
>
  <CardContent 
    sx={{ 
      color: 'white', 
      textShadow: '1px 1px 2px rgba(0,0,0,0.8)', 
      padding: { xs: '8px', md: '16px' },  
      position: 'absolute',
      bottom: { xs: 0, md: 0 },
      left: { xs: '50%', md: '20px' }, 
      transform: { xs: 'translateX(-50%)', md: 'none' }, 
      textAlign: { xs: 'center', md: 'left' }, 
      marginLeft: { xs: 0, md: '20px' }, 
      width: { xs: '100%', md: 'auto' }, 
    }}
  >
    <Typography variant="h4">{title}</Typography>
    <Typography variant="h5">{subtitle}</Typography>
    <Typography variant="body1">{location}</Typography>
    <Typography variant="body1">{date}</Typography>
    <a href={buttonLink} target="_blank" style={{ textDecoration: 'none' }}>
      <MUIButton 
        variant="tertiary" 
        sx={{ 
          mt: 2, 
          padding: { xs: '10px 50px', md: '20px 200px' },  // Adjust button size for mobile and desktop
          fontSize: { xs: '16px', md: '24px' },
          opacity: 0.8 
        }}
      >
        {buttonText}
      </MUIButton>
    </a>
  </CardContent>
</Card>

    </Box>
  );
};

export default MUISingleEventCard;
