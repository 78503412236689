// SingleEvent.js
import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";

function SingleEvent11() {

    const [heroSectionEventData] = useState({
        image: "images/Barrie-Concerts/2024-2025/bc_26_04_25.jpg",
        title: 'Piano virtuosity at its finest',
        subtitle: 'Toronto Concert Orchestra',
        date: 'Saturday 26 April 2025 7.30pm',
        location: 'Hiway Church, 50 Anne Street North, Barrie',
        buttonText: 'Book Tickets',
        buttonLink: 'https://www.showpass.com/barrie-concerts-sat-series-2024-2025-bca/ '
    });

    const [eventData] = useState({
        program: `Piano virtuosity at its finest
`,

performers: `TBA`,
        performerLink: 'https://www.tcomusic.ca/',
        ticketInfo: `Purchase your season subscription early. 6 amazing upcoming concerts. Adults Live-streaming: Available at the time of your choosing for up to 30 days after the concert - $110 Adults In-person: -  $190. Students $35 either in-person or live-streamed`,

    });

    const barrieConcert = [
        {
            title: 'Duo Turgeon',
            description: 'From Bruce with Love',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_05-10-24.jpg',
            link: '/SingleEvent6'
        },
        {
            title: 'Dévah Quartet',
            description: 'Dévah Quartet',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_30-11-24.jpg',
            link: '/SingleEvent7'
        },
        {
            title: 'True North Brass',
            description: 'Dancing Across the Global',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_18_01_25.jpg',
            link: '/SingleEvent8'
        },
    ];
    

    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}  
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink}
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Barrie Concerts" events={barrieConcert} buttonLink="/BarrieConcert" />
            </Box>
        </Box>
    );
}

export default SingleEvent11;
