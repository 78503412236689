
import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MUInavbar from './components/MUInavbar';
import MUIFooter from './components/MUIFooter';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Home from './components/pages/Home';


import News from './components/pages/News';
import News1 from './components/pages/News1.js';
import News2 from './components/pages/News2.js';
import News3 from './components/pages/News3.js'; 

import About from './components/pages/About';
import SupportUs from './components/pages/SupportUs';


import Events from './components/pages/Events';
import SingleEvent from './components/pages/SingleEvent';
import SingleEvent2 from './components/pages/SingleEvent2';
import SingleEvent3 from './components/pages/SingleEvent3';
import SingleEvent4 from './components/pages/SingleEvent4';
import SingleEvent5 from './components/pages/SingleEvent5.js';
import SingleEvent6 from './components/pages/SingleEvent6.js';
import SingleEvent7 from './components/pages/SingleEvent7.js';
import SingleEvent8 from './components/pages/SingleEvent8.js';
import SingleEvent9 from './components/pages/SingleEvent9.js';
import SingleEvent10 from './components/pages/SingleEvent10.js';
import SingleEvent11 from './components/pages/SingleEvent11.js';


import BarrieConcerts from './components/pages/BarrieConcerts.js';
import GeorgianMusic from './components/pages/GeorgianMusic.js';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import theme from './Theme/theme.js';


function App() {
  return (
    <ThemeProvider theme={createTheme(theme)}>
    <BrowserRouter>
      <div className="App">
        <MUInavbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Events' element={<Events />} />
          <Route path='/About' element={<About />} />
          <Route path='/SupportUs' element={<SupportUs />} />
          <Route path="/SingleEvent" element={<SingleEvent />} />
          <Route path="/SingleEvent2" element={<SingleEvent2 />} />
          <Route path="/SingleEvent3" element={<SingleEvent3 />} />
          <Route path="/SingleEvent4" element={<SingleEvent4 />} />
          <Route path="/SingleEvent5" element={<SingleEvent5 />} />
          <Route path="/SingleEvent6" element={<SingleEvent6 />} />
          <Route path="/SingleEvent7" element={<SingleEvent7 />} />
          <Route path="/SingleEvent8" element={<SingleEvent8 />} />
          <Route path="/SingleEvent9" element={<SingleEvent9 />} />
          <Route path="/SingleEvent10" element={<SingleEvent10 />} />
          <Route path="/SingleEvent11" element={<SingleEvent11 />} />
          <Route path="/BarrieConcerts" element={<BarrieConcerts />} />
          <Route path="/GeorgianMusic" element={<GeorgianMusic />} />
          <Route path="/News" element={<News/>} />
          <Route path="/News1" element={<News1/>} />
          <Route path="/News2" element={<News2/>} />
          <Route path="/News3" element={<News3/>} />
        </Routes>
        <MUIFooter />
      </div>
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;