// SingleEvent.js
import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";

function SingleEvent7() {

    const [heroSectionEventData] = useState({
        image: "images/Barrie-Concerts/2024-2025/bc_30-11-24.jpg",
        title: 'Dévah Quartet',
        subtitle: 'Dévah Quartet',
        date: 'Saturday 30 November 2024 7.30pm',
        location: 'Hiway Church, 50 Anne Street North, Barrie',
        buttonText: 'Book Tickets',
        buttonLink: 'https://www.showpass.com/barrie-concerts-sat-series-2024-2025-bca/ '
    });

    const [eventData] = useState({
        program: `DEVAH QUARTET - 4 strings with drummer - mix of classical, classic rock mashups and Christma, pronounced "day-vah." Sanskrit for "Heavenly Being." Electric strings meet Fuzz, Octave, Distortion
`,
        performers: `"like a refreshing version of apocalyptica"
nick lee - best of toronto

"...create then crush preconceived notions"
dakota arsenault - livemusicto

dévah recently released their third album prometheus, just on the heels of their second album 2112, which features a full length instrumental tribute to canadian icon rush's suite of the same name. prometheus has been featured in various prog rock journals and podcasts, and 2112 was featured in the uk magazine prog (dévah's second write up in this publication). dévah's tribute to rush's 2112 led to being invited to perform at a night for neil, the tribute event held for rush drummer neil peart.

a multi-genre (note: not cross-genre) string quartet, dévah has held residencies at the university of toronto's new music festival performing works on both acoustic and electric instruments, and at san jacinto college in texas as visiting artists, where they provide masterclasses and composer workshops with both sets of instruments.

dévah's notable performances include canada day at yonge and dundas square (twice), the countdown launch for the panam games, the 2022 canada games, and featured guest artist at interprovincial music camp's camp rock. when in electric mode, dévah performs high energy sets with effects pedals and wireless cables, enabling them to encourage interaction with the audience through movement. dévah quartet's shows are often performed by memory and backed by a drummer, and at times accompanied by a projection artist.

no stranger to multi-disciplinary collaboration, dévah has performed live with dancers, including toronto's a2d2 aerial dancers, and a special collaboration with ballet dancers for the kubrick: a clockwork orange installation party for the toronto international film festival.

classically trained, dévah quartet's members are active principal players in toronto-based ensembles. violinist sharon lee is concertmaster of toronto concert orchestra, violinist emily hau was former principal second of sinfonia toronto, cellist liza mclellan was cellist of quartetto gelato for nine years, violist moira burke plays for mirvish productions.

dévah became yamaha canada artists in 2011, and d'addario canada artists in 2013. `,
        performerLink: 'https://www.devahquartet.com/',
        ticketInfo: `Purchase your season subscription early. 6 amazing upcoming concerts. Adults Live-streaming: Available at the time of your choosing for up to 30 days after the concert - $110 Adults In-person: -  $190. Students $35 either in-person or live-streamed`,

    });

    const barrieConcert = [
        {
            title: 'True North Brass',
            description: 'Dancing Across the Global',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_18_01_25.jpg',
            link: '/SingleEvent8'
        },
        {
            title: 'Ensemble Caprice',
            description: 'Vivaldi on Fire',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_22_02_25.jpg',
            link: '/SingleEvent9'
        },
        {
            title: 'Francine Kay',
            description: 'Things Lived and Dreamt',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_03-11-24.jpg',
            link: '/SingleEvent10'
        }
    ];
    

    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink}
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Barrie Concerts" events={barrieConcert} buttonLink="/BarrieConcert" />
            </Box>
        </Box>
    );
}

export default SingleEvent7;
