import React from "react";
import { Box} from '@mui/material';

function MUICard4({cards4Data }) {
  return (
    <Box sx={{ textAlign: 'center', py: 2 }}>
      <Box
        component="ul"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: 10,
          padding: 0,
          listStyle: 'none',
        }}
      >
        {cards4Data.map((card, index) => (
          <Box
            component="li"
            key={index}
            sx={{
              width: 100,
              height: 100,
              borderRadius: '50%',
              overflow: 'hidden',
              backgroundColor: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            }}
          >
            <a
              href={card.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}
            >
              <Box
                component="img"
                src={card.src}
                alt={`Logo ${index + 1}`}
                sx={{
                  width: '90%',
                  height: 'auto',
                  objectFit: 'contain',
                  transition: 'all 0.2s linear',
                }}
              />
            </a>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default MUICard4;
