import React from "react";
import '../../App.css';
import HeroSectionEventCard from '../HeroSectionEventCard';
import SeasonDiscover from "../SeasonDiscover";
import MUICard2 from "../MUICard2";
import { Box } from '@mui/material';

function Events() {
  const eventData = {
    backgroundImage: '/images/bg-1.jpg',
    heading: '2024/25 Season',
    description: 'Subscriptions for 2024-2025 are now available and are an excellent value, with per concert prices about 40% lower than single ticket prices.  Subscriptions are sold in 2 series: Barrie Concerts (Saturday evenings) and Georgian Music (Sunday afternoons).  Buying both together offers even more savings.'
  };

  const seasonDiscoverData = {
    videoUrl: '/images/combined_800x600.png',
    content: [
      'Subscriptions for 2024-2025 are now available and are an excellent value, with per concert prices about 40% lower than single ticket prices',
      'Subscription tickets can be ordered and paid for online or by cheque.' ,
      'OR you can e-transfer via your own secure banking app to: payments@barrieconcerts.org',
      '*Note: Be sure to let us know how many adult and student tickets you are purchasing.',
      'Single tickets will be available about 30 days before each concert and must be ordered online.'
    ],
    payOnlineLink: 'https://www.showpass.com/barrie-concerts-georgian-music-2024-2025/',
    payByChequesLink: '/pdf/BarrieConcertOrderForm-240423.pdf',
    downloadBrochureLink:'/pdf/BCA-2024_25-Brochure.PDF'
  };

  const georgianMusic = [
    {
      title: 'Andromeda Trio',
      description: 'Tchaikovsky, Shostakovich, Haydn',
      imageUrl: 'images/Georgian-Music/2024-2025/gm_15-09-24.jpg',
      link: '/SingleEvent'
    },
    {
      title: 'Michael Kim',
      description: 'Pianist Michael Kim',
      imageUrl: 'images/Georgian-Music/2024-2025/gm_03-11-24.jpg',
      link: '/SingleEvent2'
    },
    {
      title: 'Venuti Quartet',
      description: 'Indigo',
      imageUrl: 'images/Georgian-Music/2024-2025/gm_15-12-24.jpg',
      link: '/SingleEvent3'
    },
  ];

  const barrieConcert = [
    {
      title: 'Duo Turgeon',
      description: 'From Bruce with Love!',
      imageUrl: 'images/Barrie-Concerts/2024-2025/bc_05-10-24.jpg',
      link: '/SingleEvent6'
    },
    {
      title: 'Dévah Quartet',
      description: 'Dévah Quartet',
      imageUrl: 'images/Barrie-Concerts/2024-2025/bc_30-11-24.jpg',
      link: '/SingleEvent7'
    },
    {
      title: 'True North Brass',
      description: 'Dancing Across the Global',
      imageUrl: 'images/Barrie-Concerts/2024-2025/bc_18_01_25.jpg',
      link: '/SingleEvent8'
  },
  ];


  return (
    <>
      <HeroSectionEventCard
        backgroundImage={eventData.backgroundImage}
        heading={eventData.heading}
        description={eventData.description}
      />
      <SeasonDiscover videoUrl={seasonDiscoverData.videoUrl} payOnlineLink={seasonDiscoverData.payOnlineLink}  payByChequesLink={seasonDiscoverData.payByChequesLink} downloadBrochureLink={seasonDiscoverData.downloadBrochureLink} content={seasonDiscoverData.content} />
      <Box marginY={4}>
        <MUICard2 title="2024/25 Georgian Music" events={georgianMusic} buttonLink="/GeorgianMusic" />
      </Box>
      <Box marginY={4}>
        <MUICard2 title="2024/25 Barrie Concerts" events={barrieConcert} buttonLink="/BarrieConcerts" />
      </Box>
    </>
  );
}

export default Events;
